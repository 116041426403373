<template>
    <user-dashboard-layout>
        <v-row>

            <v-card>
                <v-card-title>Incsepction res</v-card-title>
                <v-card-actions>
                    <v-btn @click="$router.push('/admin/inspections/residential')">Select</v-btn>
                </v-card-actions>
            </v-card>

            <v-card>
                <v-card-title>Inspection com </v-card-title>
                <v-card-actions>
                    <v-btn @click="$router.push('/admin/inspections/commercial')">Select</v-btn>

                </v-card-actions>
            </v-card>


        </v-row>
    </user-dashboard-layout>
</template>

<script>
import PricePlan from "../../../models/PricePlan";
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";

export default {
    name: "Index",
    components: { UserDashboardLayout},
    data: function () {
        return {
            pricePlans: []
        }
    },
    methods: {
        handlePlanSelect(plan) {
            this.$router.push({name: 'dashboard.billing.checkout', params: {plan}})
        }
    },
    async mounted() {
        this.pricePlans = await PricePlan.get()
    },
}
</script>

<style scoped>

</style>
